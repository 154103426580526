<template>
	<HeroPage v-if="pageData" :data="pageData" />
	<!-- <section class="error 404">
		<div class="container">
			<div class="column-wrapper">
				<div class="column content">
					<p>
						The page <strong>{{ getUrlLocation }}</strong> has not been found or
						no longer exists.
					</p>
					<p>
						Click the button below to go back to home
					</p>
					<nav class="error-nav">
						<ul>
							<li>
								<router-link class="button" data-txt="Home" to="/"
									>Home</router-link
								>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</section> -->
</template>

<script>
import { fetchPageBySlug } from "@streampac.io/chef_sp_1";

import HeroPage from "@/components/HeroPage";

export default {
	data() {
		return {
			pageData: null,
		};
	},
	components: {
		HeroPage,
	},
	computed: {
		getUrlLocation() {
			return window.location.pathname;
		},
	},
	created() {
		this.fetchPage();
	},
	methods: {
		fetchPage() {
			fetchPageBySlug(this.isAuthenticated, "error-404").then(
				(response) => {
					this.pageData = response;
				}
			);
		},
	},
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
